@import "../../../index.scss";

.doings {
  @extend .indexPad;
}
.upper {
  text-align: center;
}

.para_1 {
  color: $frequent-color;
  font-family: Medula One;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2rem;
}

.para_2 {
  color: $frequent-color;
  text-align: center;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */

  @include responsive(phone) {
    font-size: 1.2rem;
  }
}

.card {
  //   height: 100%;
  margin-bottom: 10px;
  border: 2px solid rgba(18, 89, 22, 0.85);

  &_para_1 {
    background: rgba(18, 89, 22, 0.85);
    padding: 3rem 2rem;
    color: $primary-color;
    text-align: center;
    // font-family: Master;
    font-family: "Russo One", sans-serif;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 400;
    // line-height: normal;

    @include responsive(tab) {
      font-size: 2rem;
    }
  }
  &_para_2 {
    background: #fff;
    display: flex;
    padding: 2rem 4rem;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    // color: #fff;
  }

  &_link {
    color: #1e1e1e;

    /* buttons */
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    text-decoration: none;
    font-weight: 700;
    line-height: normal;
  }
}

// Why choose us

.choose {
  padding-top: 3rem;
}
.side {
  height: 80vh;

  &_1 {
    height: 80vh;
    height: 80vh;
    background-size: cover;
    background-position: center;

    background-image: url("../../../assets/choose1.png");
  }
  &_2 {
    height: 70vh;
    background-size: cover;
    background-position: center;
    margin-top: 2rem;
    //    margin-bottom: 3rem;
    background-image: url("../../../assets/choose2.png");
    color: #1e1e1e;
  }
  &_2 {
    height: 70vh;
  }
}

.choose_para {
  &_1 {
    margin-top: 2rem;
    // color: #125916;
    font-family: Medula One;

    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_2 {
    // color: #125916;
    // font-family: Master;
    font-family: "Russo One", sans-serif;

    font-size: 1.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_3 {
    // color: #125916;
    font-family: Open Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}