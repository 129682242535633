@import '../../../index.scss';
.footer {
  max-width: 100%;
  height: 60vh;
padding: 3.5rem;
border-top: 3px solid #125916;
// border-bottom: 3px solid #125916;

@include responsive(phone){
      padding: 1.5rem;

  }
@include responsive(tab){
      padding: 2rem;

  }
}

.title {
  display: flex;

  &_text {
    color: #125916;
    font-family: Fredoka One;
    font-size: 42.156px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &_sub {
    color: #125916;
    font-family: Medula One;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: right;
    margin-top: -0.8rem;
    
    @include responsive(tab){
        text-align: center;

    }
  }
}

.quick {
  color: #125916;
  font-family: Medula One;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;

  &_sub {
    color: #125916;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-decoration: none;
  }
}




.line{
    width: 100%;
    border-bottom: 3px solid #125916;
    padding-bottom: .5rem;
    display: flex;
    justify-content: flex-end;
    padding-top: 3rem;
    
    @include responsive(phone){
        
        justify-content: center;
    }
}

.link{
   
    padding: .5rem;
    background-color: #125916;
    border-radius: 10%;
    margin-right: .5rem;
    color: white;
    
    text-decoration: none;
}