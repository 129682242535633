@import "../../../index.scss";

.about {
  height: 100vh;
  max-width: 100%;
  @extend .indexPad;

  @include responsive(tab) {

    height: auto;
  }
}

.side {
  &_1 {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../../assets/about-us.png");
    height: 80vh;
    background-size: cover;
    background-position: center;
    border-radius: 1rem;
    margin: 2rem;

    @include responsive(tab) {
      //   height: 60vh !important;
      margin: 1rem;
      border-radius: 0.7;
    }
    @include responsive(tab) {
      height: 60vh;
      margin: 1rem;
      border-radius: 0.7;
    }
  }
}

.para_1 {
  color: $frequent-color;
  padding-top: 5%;
  font-family: Medula One;
  font-size: 3rem;
  font-weight: 500;
}

.para_2 {
  color: $frequent-color;
  font-family: Open Sans;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: justify;
//   line-height: normal;
}
