

$primary-color:#fff;
$frequent-color:#125916;
$frequent-color1: #01d28e;
@mixin responsive($breakPoint) {
  @if $breakPoint==tab {
      @media screen and (max-width:768px){@content}
  }
  @if $breakPoint==phone {
      @media screen and (max-width:430px){@content}
  }

}

.hideOnDesktop{
  visibility: hidden;
  @include responsive(tab){
    visibility: visible;
  }
  @include responsive(phone){
    visibility: visible !important;
  }
 
}
.hideOnMobile{
  visibility: visible;
  @include responsive(tab){
    visibility: hidden;
  }
}


.indexPad{
  padding: 1rem 4.5rem ;
  @include responsive(tab){
    padding: .5rem 2.5rem;
  }
  @include responsive(phone){
    padding: .5rem 1.5rem;
  }
}


