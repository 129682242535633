@import "../../../index.scss";

.goal {
  margin-top: 5rem;
  max-width: 100%;
  height: 100vh;
  background-image: url("../../../assets/bg2.png");
  background-size: cover;
  background-position: center;
  @extend .indexPad;

  @include responsive(tab) {
    height: auto;
    padding-bottom: 2rem;
  }
}

.para {
  padding: 8rem 5rem 5rem 32rem;

  @include responsive(phone) {
    padding: 0rem;
  }
  @include responsive(tab) {
    padding: 0rem;
  }

  &_1 {
    color: #fff;
    font-family: Master;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_2 {
    color: #fff;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */

    @include responsive(tab) {
      font-size: 1.7rem;
    }
    @include responsive(phone) {
      font-size: 1.5rem;
    }
  }
  // &_3 {
  //   padding: 16px 65px;
  //   gap: 10px;
  //   background: #d9d9d9;
  //   color: #000;
  //   font-family: Open Sans;
  //   font-size: 1.8rem;
  //   font-style: normal;
  //   font-weight: 700;
  //   line-height: normal;
  //   text-decoration: none;
  // }
}
