@import "../../../index.scss";

.banner {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background-repeat: no-repeat;
  // background-image: url("../../../assets/bg1.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  overflow-x: hidden;
  animation-name: animate;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  transition: all 3s;

@extend .indexPad;


  @include responsive(tab) {
    text-align: center;
  }
}

@keyframes animate {
  0% {
    background-image: url("../../../assets/bg3.png");
  }
  50% {
    background-image: url("../../../assets/bg4.png");
  }
  70% {
    background-image: url("../../../assets/bg5.png");
  }
  100% {
    background-image: url("../../../assets/bg6.png");
  }
}

.para {
  width: 50%;
  color: $primary-color;
  padding-top: 8%;
  padding-left: 2%;
  padding-bottom: 1rem;

  @include responsive(tab) {
    width: 100%;
    padding-top: 20% !important;
    line-height: 1.7;
  }
  @include responsive(phone) {
    width: 100% !important;
    padding-top: 15% !important;
    padding-left: 0px;
    line-height: 1.7;
  }

  &_1 {
    font-family: "Caprasimo", cursive;
    font-family: Master;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @include responsive(tab) {
      font-size: 2.8rem;
    }
    @include responsive(phone) {
      font-size: 2.3rem;
    }
  }

  &_2 {
    font-family: Open Sans;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include responsive(tab) {
      font-size: 1.5rem;
    }
    @include responsive(phone) {
      font-size: 1.2rem;
    }
  }

  &_3 {
    color: $primary-color;
    padding-left: 2%;
    display: flex;
    justify-content: space-between;

    @include responsive(tab) {
      margin-left: 30%;
    }
    @include responsive(phone) {
      margin-left: 30%;
    }
  }
}

.btn {
  padding: 0.8rem 3rem;
  gap: 10px;
  border-radius: 12px;
  background: #f8f8f8;
  color: $frequent-color;
  font-family: Open Sans;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  @include responsive(tab) {
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
  }
  @include responsive(phone) {
    padding: 1rem 1rem;
  }
}
.dots {
  @extend .hideOnMobile;
}
