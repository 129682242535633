@import "../../../index.scss";
@import "../whatWeDo/doings.module.scss";

.team {
  margin-top: 2rem;
  text-align: center;
  @extend .indexPad;

}

.para_1 {
  color: #1E1E1E;

  @extend .para_1;
}

.para_2 {
  color: #1E1E1E;
  font-size: 1.3rem;
  @extend .para_2;
  padding: 0px 10rem;

  @include responsive(tab) {
    padding: 0px 2rem;
  }
}

// card

.card {
  max-width: 100%;
  height: 40vh;
  // max-height: 100vh;
  border-radius: 17px;
  background: rgba(18, 89, 22, 0.25);
  text-align: center;
  @include responsive(tab){
    height: 50vh;
  }
  @include responsive(phone){
    height: 70vh;
  }
}

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.para {
  color: #125916;
  font-family: Master;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 2rem;
}
.readMore_btn {
  // display: flex;
  padding: .7rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #fff;
  color: #125916;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  }
