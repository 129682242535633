@import "../../index.scss";

.hideOnDestop {
  @extend .hideOnDestop;
}

.hideOnMobile {
  @extend .hideOnMobile;
}

.header {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-left: 1.5rem;
  overflow-x: hidden;
  // justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
  }
}

.active {
  background-color: black;
  // color: black ;
}

.logo {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 0.7rem;
}

.title {
  max-width: 300px;
  min-width: 400px;
  display: flex;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
  font-family: Fredoka One;
  font-size: 2rem;
  font-weight: 700;
  line-height: 51px;
  color: #125916;

  @include responsive(tab) {
    min-width: 100px;
    font-weight: 900;
    max-width: 100px;
    font-size: 14px;
  }
}

.navs {
  display: flex;
  flex: 1;
  margin-top: 1rem;
}

.nav_items {
  text-decoration: none;
  margin-left: 40px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn {
  display: inline-flex;
  padding: 1rem 2rem;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  color: #125916;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  box-shadow: 1px 1px 5px rgba(0,0,0,.7);
}


.icon{
   @include responsive(phone){
      margin-left: -500px;
   }
}

.resp_navs{
  display: block;
  text-decoration: none;
  font-size: 17px;
  padding-top: 20px;
  color: black;
  font-weight: 700;
  // text-align: center;


  &_logo{
     padding-right: 10px;
     color: #125916 !important;
  }

}